import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUpdateContext } from '../context/Update-context';

const Header = () => {
  const navigate = useNavigate();

  const { setIsloggedIn } = useUpdateContext();

  const logoutHandler = () => {
    localStorage.setItem('logged_in', false);
    setIsloggedIn(false);
    navigate('/', { replace: true });
  };
  return (
    <>
      <header className='navbar sticky-top navbar-light bg-light flex-md-nowrap p-0 shadow'>
        <Link
          className='navbar-brand col-md-3 col-lg-2 me-0 px-3'
          to='/dashboard'>
          Dashboard
        </Link>
        <div className='navbar-nav'>
          <div className='nav-item text-nowrap'>
            <button
              className='nav-link px-3 btn-light-main btn'
              onClick={logoutHandler}>
              Sign out
            </button>
          </div>
        </div>
        <button
          className='navbar-toggler position-absolute d-md-none collapsed'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#sidebarMenu'
          aria-controls='sidebarMenu'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'></span>
        </button>
        {/* <input
          className='form-control form-control-dark w-100'
          type='text'
          placeholder='Search'
          aria-label='Search'
        /> */}
      </header>
    </>
  );
};

export default Header;
