import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { showDialog, HomeURL } from '../helpers';

const UpdateContext = React.createContext({
  data: [],
  setData: [],
  dataService: [],
  setDataService: [],
  dataAbout: [],
  setDataAbout: [],
  dataContact: [],
  setDataContact: [],
  dataWork: [],
  setDataWork: [],
  isLoggedIn: false,
  setIsloggedIn: (status) => {},
  addNewData: (newData) => {},
});

export const UpdateProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [dataService, setDataService] = useState({});
  const [dataAbout, setDataAbout] = useState({});
  const [dataContact, setDataContact] = useState({});
  const [dataWork, setDataWork] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem('logged_in'))
  );
  const getHomePageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/homePage.json')
      .then((res) => {
        const tempData = [];
        for (const key in res.data) {
          const data = {
            id: key,
            bannerImage: res.data[key].bannerImage,
            data: res.data[key].data,
          };
          tempData.push(data);
        }
        // console.log(tempData[0]);
        setData(tempData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getHomePageData, []);

  const getServicePageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/servicePage.json')
      .then((res) => {
        const tempData = [];

        for (const key in res.data) {
          const data = {
            id: key,
            service: res.data[key].service,
            sms: res.data[key].sms,
            voice: res.data[key].voice,
          };
          tempData.push(data);
        }
        setDataService(tempData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getServicePageData, []);

  const getAboutPageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/aboutPage.json')
      .then((res) => {
        const tempData = [];
        for (const key in res.data) {
          const data = {
            id: key,
            title: res.data[key].title,
            image: res.data[key].image,
            desc: res.data[key].desc,
          };
          tempData.push(data);
        }
        setDataAbout(tempData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getAboutPageData, []);

  const getContactPageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/contactPage.json')
      .then((res) => {
        const tempData = [];
        for (const key in res.data) {
          const data = {
            id: key,
            title: res.data[key].title,
            image: res.data[key].image,
            desc: res.data[key].desc,
          };
          tempData.push(data);
        }
        setDataContact(tempData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getContactPageData, []);

  const getWorkPageData = () => {
    axios
      .get('https://call4china-default-rtdb.firebaseio.com/workPage.json')
      .then((res) => {
        const tempData = [];
        for (const key in res.data) {
          const data = {
            id: key,
            title: res.data[key].title,
            image: res.data[key].image,
            desc: res.data[key].desc,
          };
          tempData.push(data);
        }
        setDataWork(tempData[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(getWorkPageData, []);

  const addNewDataHandler = (newData) => {
    setData((prev) => {
      return [newData, ...prev];
    });
  };

  /* const fetchData = () => {
    axios
      .get(HomeURL)
      .then((res) => {
        const tempProducts = [];
        for (const key in res.data) {
          const product = {
            Key: key,
            name: res.data[key].name,
            image: res.data[key].image,
            desc: res.data[key].description,
            price: res.data[key].price,
          };
          tempProducts.push(product);
        }
        setDatas(tempProducts);
      })
      .catch((err) => {
        showDialog('Error', 'Error', 'error');
      });
  };
  useEffect(fetchData, []);
*/
  return (
    <UpdateContext.Provider
      value={{
        data: data,
        isLoggedIn: isLoggedIn,
        setIsloggedIn: setIsLoggedIn,
        setData: setData,
        addNewData: addNewDataHandler,
        dataService: dataService,
        setDataService: setDataService,
        dataAbout: dataAbout,
        setDataAbout: setDataAbout,
        dataContact: dataContact,
        setDataContact: setDataContact,
        dataWork: dataWork,
        setDataWork: setDataWork,
      }}>
      {children}
    </UpdateContext.Provider>
  );
};
export const useUpdateContext = () => {
  return useContext(UpdateContext);
};
