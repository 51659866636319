import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/custom.css';
import LoginPage from './pages/LoginPage';
import AppRoutes from './routes/AppRoutes';
import { UpdateProvider } from './context/Update-context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UpdateProvider>
    <AppRoutes />
  </UpdateProvider>
);
