import React from 'react';
import { NavLink } from 'react-router-dom';
import '../assets/css/dropdown.css';

const Sidebar = () => {
  return (
    <>
      <nav
        id='sidebarMenu'
        className='col-md-3 col-lg-2 d-md-block bg-light sidebar collapse'>
        <div className='position-sticky pt-3'>
          <ul className='nav flex-column'>
            <li className='nav-item'>
              <NavLink
                className={`nav-link ${(data) =>
                  data.isActive ? 'active' : ''}`}
                aria-current='page'
                to='/dashboard'
                end>
                <span data-feather='home'></span>
                Home Page
              </NavLink>
            </li>

            <li className='nav-item dropdown'>
              <NavLink
                className={`dropdown-toggle nav-link ${(data) =>
                  data.isActive ? 'active' : ''}`}
                to='/dashboard/services'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'>
                Services Page
              </NavLink>
              <ul className='nav flex-column dropdown-content'>
                <li className='nav-item '>
                  <NavLink
                    className={`nav-link ${(data) =>
                      data.isActive ? 'active' : ''}`}
                    to='/dashboard/voice'>
                    Voice
                  </NavLink>
                </li>
                <li className='nav-item '>
                  <NavLink
                    className={`nav-link ${(data) =>
                      data.isActive ? 'active' : ''}`}
                    to='/dashboard/sms'>
                    SMS
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className='nav-item'>
              <NavLink
                className={`nav-link ${(data) =>
                  data.isActive ? 'active' : ''}`}
                to='/dashboard/about'>
                About Page
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                className={`nav-link ${(data) =>
                  data.isActive ? 'active' : ''}`}
                to='/dashboard/contact'>
                Contact Page
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink
                className={`nav-link ${(data) =>
                  data.isActive ? 'active' : ''}`}
                to='/dashboard/work'>
                Work Page
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
