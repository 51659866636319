import React from 'react';
import TaskItem from '../../TaskItem';
import Image from '../../../assets/img/1.png';
import { useUpdateContext } from '../../../context/Update-context';
import { Link } from 'react-router-dom';
const Slider = () => {
  const { data } = useUpdateContext();
  const { bannerImage } = data;

  return (
    <div className='row'>
      <div className='col-md-12 d-flex align-items-center justify-content-between mb-4'>
        <h2
          style={{
            color: 'GrayText',
            fontFamily: 'monospace',
          }}>
          Slider Section
        </h2>
        <Link
          to='/dashboard/new-image'
          className='pull-right btn btn-main mb-4 pr-4'>
          New Image
        </Link>
      </div>
      {bannerImage.map((img, key) => (
        <TaskItem key={key} id={key} image={img} />
      ))}
    </div>
  );
};

export default Slider;
