import React, { useRef, useState } from 'react';
import '../../../assets/css/serviceSection.css';
import { useUpdateContext } from '../../../context/Update-context';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { showDialog, storage } from '../../../helpers';
import axios from 'axios';

const MainSection = () => {
  const { dataService, setDataService } = useUpdateContext();
  const serviceData = dataService.service;
  const [title, setTitle] = useState(serviceData.title);
  const [desc, setDesc] = useState(serviceData.desc);
  const [img, setImg] = useState(serviceData.image);
  const [progresspercent, setProgresspercent] = useState(0);
  const imgRef = useRef();
  const descRef = useRef();

  const tempData = dataService;

  const onSubmitHandler = (e) => {
    e.preventDefault();
    tempData.service.image = img;
    tempData.service.desc = desc;
    const dataId = tempData.id;
    axios
      .put(
        `https://call4china-default-rtdb.firebaseio.com/servicePage/${dataId}.json`,
        tempData
      )
      .then((res) => {
        setDataService(res.data);
        showDialog('success', 'Updated successfully', 'success');
        resetField();
      })
      .catch((err) => {
        showDialog('Error', 'Error', 'error');
      });
  };
  const handleMessageChange = (e) => {
    setDesc(e.target.value);
  };
  const resetField = () => {
    imgRef.current.value = '';
    setProgresspercent(0);
  };
  const upload = async (file) => {
    if (!file) return;
    const storageRef = ref(storage, file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImg(downloadURL);
        });
      }
    );
  };
  return (
    <>
      <div className='row mt-3'>
        <div className='col-md-12 d-flex align-items-center justify-content-between mb-4'>
          <h2
            style={{
              color: 'GrayText',
              fontFamily: 'monospace',
            }}>
            Service Page
          </h2>
        </div>
        <section className='service-container'>
          <div className='service'>
            <div
              className='service-text'
              data-aos='fade-left'
              data-aos-duration='500'>
              <h2>{title}</h2>
              <p>{desc}</p>
            </div>
            <div
              className='service-img'
              data-aos='fade-right'
              data-aos-duration='500'>
              <img src={img} alt='' />
            </div>
          </div>
        </section>

        <section className='row mt-3'>
          <div className='row mt-5'>
            <form onSubmit={onSubmitHandler}>
              <div className='col-md-12'>
                <div className='form-outline mb-4'>
                  <label className='form-label'>Description</label>
                  <textarea
                    ref={descRef}
                    name=''
                    id=''
                    cols='30'
                    rows='7'
                    className='form-control'
                    placeholder='Task description'
                    value={desc}
                    onChange={handleMessageChange}></textarea>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-outline mb-4'>
                  <label className='form-label'>Image</label>
                  <input
                    ref={imgRef}
                    className='form-control'
                    type='file'
                    id='formFile'
                    onChange={(e) => {
                      upload(e.target.files[0]);
                    }}
                  />
                  <input
                    style={{ width: '100%' }}
                    type='range'
                    id='volume'
                    name='volume'
                    min='0'
                    max='100'
                    readOnly
                    value={progresspercent}
                  />
                </div>
              </div>

              <div>
                <button
                  type='submit'
                  className='pull-right btn btn-main mb-4 pr-4'>
                  Update
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default MainSection;
