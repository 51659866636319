import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateContext } from '../context/Update-context';
import axios from 'axios';
import { apiKey, showDialog } from '../helpers';

const LoginPage = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const { setIsloggedIn } = useUpdateContext();

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (checkField()) {
      login();
    }
  };
  const login = () => {
    axios
      .post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${apiKey}`,
        {
          email: emailRef.current.value,
          password: passwordRef.current.value,
          returnSecureToken: true,
        }
      )
      .then((res) => {
        localStorage.setItem('logged_in', true);
        setIsloggedIn(true);
        navigate('/dashboard', { replace: true });
      })
      .catch((err) => {
        showDialog('Error', 'Invalid login', 'error');
      });
  };

  const checkField = () => {
    if (emailRef.current.value != '' && passwordRef.current.value != '') {
      return true;
    }
    showDialog('Error', 'Enter login Credentials', 'error');
    return false;
  };

  return (
    <>
      <div className='container-fluid p-5  text-white text-center login-cover'></div>

      <div className='container'>
        <div className='row'>
          <div className='col-sm-1'></div>

          <div className='col-sm-10'>
            <div className='shadow-5-strong form-box'>
              {/* <ul
            className='nav nav-pills nav-justified mb-3'
            id='ex1'
            role='tablist'>
            <li className='nav-item' role='presentation'>
              <a
                className='nav-link active'
                id='tab-login'
                data-bs-toggle='pill'
                href='#pills-login'
                role='tab'
                aria-controls='pills-login'
                aria-selected='true'>
                Login
              </a>
            </li>
            <li className='nav-item' role='presentation'>
              <a
                className='nav-link'
                id='tab-register'
                data-bs-toggle='pill'
                href='#pills-register'
                role='tab'
                aria-controls='pills-register'
                aria-selected='false'>
                Register
              </a>
            </li>
          </ul> */}

              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='pills-login'
                  role='tabpanel'
                  aria-labelledby='tab-login'>
                  <form onSubmit={onSubmitHandler}>
                    <div className='text-center mb-3'>
                      <h4 className='mb-4 mt-5'>Login </h4>
                      {/*<button
                    type='button'
                    className='btn btn-link btn-floating mx-1'>
                    <i className='fab fa-facebook-f'></i>
                  </button>

                  <button
                    type='button'
                    className='btn btn-link btn-floating mx-1'>
                    <i className='fab fa-google'></i>
                  </button>

                  <button
                    type='button'
                    className='btn btn-link btn-floating mx-1'>
                    <i className='fab fa-twitter'></i>
                  </button>

                  <button
                    type='button'
                    className='btn btn-link btn-floating mx-1'>
                    <i className='fab fa-github'></i>
                  </button>*/}
                    </div>

                    {/* <h4 className='mb-5 mt-2 text-center'>or</h4> */}

                    <div className='form-outline mb-4'>
                      <input
                        ref={emailRef}
                        type='email'
                        id='loginName'
                        className='form-control'
                        placeholder='Email'
                      />
                    </div>

                    <div className='form-outline mb-4'>
                      <input
                        ref={passwordRef}
                        type='password'
                        id='loginPassword'
                        className='form-control'
                        placeholder='Password'
                      />
                    </div>

                    <button
                      type='submit'
                      className='btn btn-main btn-block mb-4'>
                      Sign in
                    </button>

                    {/* <div className='text-center'>
                  <p>
                    Not a member? <a href='#!'>Register</a>
                  </p>
                </div> */}
                  </form>
                </div>
                {/* register */}
                {/* <div
              className='tab-pane fade'
              id='pills-register'
              role='tabpanel'
              aria-labelledby='tab-register'>
              <form>
                <div className='text-center mb-3'>
                  <h4 className='mb-4 mt-5'>
                    Register in Task System with
                  </h4>
                  <button
                    type='button'
                    className='btn btn-link btn-floating mx-1'>
                    <i className='fab fa-facebook-f'></i>
                  </button>

                  <button
                    type='button'
                    className='btn btn-link btn-floating mx-1'>
                    <i className='fab fa-google'></i>
                  </button>

                  <button
                    type='button'
                    className='btn btn-link btn-floating mx-1'>
                    <i className='fab fa-twitter'></i>
                  </button>

                  <button
                    type='button'
                    className='btn btn-link btn-floating mx-1'>
                    <i className='fab fa-github'></i>
                  </button>
                </div>

                <h4 className='mb-4 mt-5 text-center'>or:</h4>

                <div className='form-outline mb-4'>
                  <input
                    type='text'
                    id='registerName'
                    className='form-control'
                    placeholder='Name'
                  />
                </div>

                <div className='form-outline mb-4'>
                  <input
                    type='text'
                    id='registerUsername'
                    className='form-control'
                    placeholder='Username'
                  />
                </div>

                <div className='form-outline mb-4'>
                  <input
                    type='email'
                    id='registerEmail'
                    className='form-control'
                    placeholder='Email'
                  />
                </div>

                <div className='form-outline mb-4'>
                  <input
                    type='password'
                    id='registerPassword'
                    className='form-control'
                    placeholder='password'
                  />
                </div>

                <div className='form-outline mb-4'>
                  <input
                    type='password'
                    id='registerRepeatPassword'
                    className='form-control'
                    placeholder='repeat password'
                  />
                </div>

                <div className='form-check d-flex justify-content-center mb-4'>
                  <input
                    className='form-check-input me-2'
                    type='checkbox'
                    value=''
                    id='registerCheck'
                    defaultChecked={true}
                    aria-describedby='registerCheckHelpText'
                  />
                  <label
                    className='form-check-label'
                    htmlFor='registerCheck'>
                    I have read and agree to the terms
                  </label>
                </div>

                <button
                  type='submit'
                  className='btn btn-main btn-block mb-3'>
                  Sign in
                </button>
              </form>
            </div> */}
              </div>
            </div>
          </div>

          <div className='col-sm-1'></div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
