import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useUpdateContext } from '../context/Update-context';

const TaskItem = ({ id, image }) => {
  const { data, setData } = useUpdateContext();
  const tempData = data;

  const deleteImageFromFirebase = (id) => {
    const tempImages = [];
    for (const key in data.bannerImage) {
      if (key != id) {
        tempImages.push(data.bannerImage[key]);
      }
    }
    tempData.bannerImage = tempImages;
    const dataId = tempData.id;

    axios
      .put(
        `https://call4china-default-rtdb.firebaseio.com/homePage/${dataId}.json`,
        tempData
      )
      .then((res) => {
        setData(res.data);
      })
      .catch();
  };

  const onDeleteHandler = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteImageFromFirebase(id);
        Swal.fire({
          icon: 'success',
          title: 'Great',
          text: 'Image deleted successfully',
          showConfirmButton: false,
          timer: 1000,
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
          }
        });
      }
    });
  };

  return (
    <>
      <div className='col-md-4 mb-4'>
        <div
          className='card task card'
          style={{ width: '100%', height: '100%' }}>
          <img
            src={image}
            className='card-img-top'
            alt='...'
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
          <div className='card-body'>
            <hr />
            <div className='price-div'></div>
            <div className='button-div'>
              <button
                className='btn btn-bg-gray pull-right'
                onClick={() => onDeleteHandler(id)}>
                <i className='fa-solid fa-trash-can'></i>
              </button>
              <Link
                to={`/dashboard/images/${id}`}
                className='btn btn-bg-gray pull-right '>
                <i className='fa fa-pencil' aria-hidden='true' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskItem;
