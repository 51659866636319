import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useUpdateContext } from '../context/Update-context';
import LoginPage from '../pages/LoginPage';
import DashboardPage from '../pages/DashboardPage';
import DashboardHome from '../components/DashboardHome';
import ContactPage from '../pages/ContactPage';
import AboutePage from '../pages/AboutePage';
import ServicesPage from '../pages/ServicesPage';
import VoicePage from '../pages/VoicePage';
import { SmsPage } from '../pages/SmsPage';
import HomePage from '../pages/HomePage';
import UpdateImages from '../components/componentPages/homePage/UpdateImages';
import NewImage from '../components/componentPages/homePage/NewImage';
import WorkPage from '../pages/WorkPage';

const AppRoutes = () => {
  const { isLoggedIn } = useUpdateContext();
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route
          path='/dashboard'
          element={
            isLoggedIn != true ? <Navigate to='/' /> : <DashboardPage />
          }>
          <Route index element={<HomePage />} />
          <Route path='images/:id' element={<UpdateImages />} />
          <Route path='new-image' element={<NewImage />} />
          <Route path='services' element={<ServicesPage />} />
          <Route path='voice' element={<VoicePage />} />
          <Route path='sms' element={<SmsPage />} />

          <Route path='about' element={<AboutePage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='work' element={<WorkPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
/*<Route
          path='/products'
          element={
            isLoggedIn != true ? <Navigate to='/' /> : <DashboardPage />
          }>
          <Route index element={<DashboardHome />} />
          <Route path=':id' element={<TaskDetails />} />
          <Route path='new-product' element={<NewTask />} />
        </Route>
        <Route
          path='/login'
          element={
            isLoggedIn != true ? <LoginPage /> : <Navigate to='/products' />
          }
        />
        <Route path='/' element={<Navigate to='/login' />} />
        <Route
          path='/*'
          element={
            isLoggedIn != true ? (
              <Navigate to='/login' />
            ) : (
              <Navigate to='/products' />
            )
          }
        /> */
export default AppRoutes;
