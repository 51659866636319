import React from 'react';
import { useUpdateContext } from '../context/Update-context';
import Loading from '../components/Loading';
import Slider from '../components/componentPages/homePage/Slider';
import ServiceSection from '../components/componentPages/homePage/ServiceSection';
import AboutSection from '../components/componentPages/homePage/AboutSection';
import WorkSection from '../components/componentPages/homePage/WorkSection';

const HomePage = () => {
  const { data } = useUpdateContext();

  return (
    <>
      <main className='col-md-9 ms-sm-auto col-lg-10 px-md-4'>
        <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
          <h1 className='h2 mt-3'>Home Page </h1>
        </div>

        {data ? (
          <div className='row mt-5'>
            <Slider />
            <hr />
            <ServiceSection />
            <hr />
            <AboutSection />
            <hr />
            <WorkSection />
          </div>
        ) : (
          <Loading />
        )}
      </main>
    </>
  );
};

export default HomePage;
