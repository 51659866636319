import React, { useRef, useState } from 'react';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { HomeURL, showDialog, storage } from '../../../helpers';
import { useUpdateContext } from '../../../context/Update-context';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const UpdateImages = () => {
  const { data, setData } = useUpdateContext();
  const { bannerImage } = data;
  const { id } = useParams();
  const [image, setImage] = useState(bannerImage[id]);
  const [url, setUrl] = useState('');
  const [progresspercent, setProgresspercent] = useState(0);
  const urlRef = useRef();

  const tempData = data;
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const tempImages = [];
    for (const key in data.bannerImage) {
      tempImages.push(data.bannerImage[key]);
      if (key == id) tempImages[id] = url;
    }
    tempData.bannerImage = tempImages;
    const dataId = tempData.id;
    const resetField = () => {
      urlRef.current.value = '';
      setProgresspercent(0);
      setUrl('');
    };
    axios
      .put(
        `https://call4china-default-rtdb.firebaseio.com/homePage/${dataId}.json`,
        tempData
      )
      .then((res) => {
        setData(res.data);
        showDialog('success', 'Updated Image successfully', 'success');
        resetField();
      })
      .catch((err) => {
        showDialog('Error', 'Error', 'error');
      });
  };

  const upload = async (file) => {
    if (!file) return;
    const storageRef = ref(storage, file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          setImage(downloadURL);
        });
      }
    );
  };
  return (
    <>
      <main className='col-md-9 ms-sm-auto col-lg-10 px-md-4'>
        <div className='d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
          <h1 className='h2 mt-3'>Update Image</h1>
          <Link to='/dashboard' className='pull-right btn btn-main mb-4 pr-4 '>
            back
          </Link>
        </div>

        <div className='row mt-5'>
          <div className='col-md-4'>
            <div className='card task card'>
              <img src={image} className='card-img-top' alt='...' />
            </div>
          </div>
          <form onSubmit={onSubmitHandler}>
            <div className='col-md-12'>
              <div className='form-outline mb-4'>
                <label className='form-label'>Image For Slider</label>
                <input
                  className='form-control'
                  type='file'
                  id='formFile'
                  onChange={(e) => {
                    upload(e.target.files[0]);
                    setImage(e.target.files[0]);
                  }}
                  ref={urlRef}
                />
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='pull-right btn btn-main mb-4 pr-4'
                disabled={url ? false : true}>
                {url ? 'Update Image' : `${progresspercent}%`}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default UpdateImages;
