import React from 'react';
import VoiceSection from '../components/componentPages/servicePage/VoiceSection';
import { useUpdateContext } from '../context/Update-context';
import Loading from '../components/Loading';

const VoicePage = () => {
  const { dataService } = useUpdateContext();
  return (
    <>
      <main className='col-md-9 ms-sm-auto col-lg-10 px-md-4'>
        <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
          <h1 className='h2 mt-3'>Voice Page</h1>
        </div>

        <div className='row mt-5'>
          {dataService ? (
            <div className='row mt-5'>
              <VoiceSection />
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </main>
    </>
  );
};

export default VoicePage;
