import React, { useRef, useState } from 'react';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { showDialog, storage } from '../../../helpers';
import { Link } from 'react-router-dom';
import { useUpdateContext } from '../../../context/Update-context';
import axios from 'axios';

const NewImage = () => {
  const urlRef = useRef();
  const { data, setData } = useUpdateContext();
  const { bannerImage } = data;
  const [img, setImg] = useState('');
  const [url, setUrl] = useState(
    'https://img.freepik.com/free-vector/white-abstract-background-design_23-2148825582.jpg?w=2000'
  );
  const [progresspercent, setProgresspercent] = useState(0);
  const tempData = data;

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const tempImages = [];
    for (const key in data.bannerImage) {
      tempImages.push(data.bannerImage[key]);
    }
    tempImages.push(img);
    tempData.bannerImage = tempImages;
    const dataId = tempData.id;
    const resetField = () => {
      urlRef.current.value = '';
      setUrl(
        'https://img.freepik.com/free-vector/white-abstract-background-design_23-2148825582.jpg?w=2000'
      );
      setImg('');
      setProgresspercent(0);
    };
    axios
      .put(
        `https://call4china-default-rtdb.firebaseio.com/homePage/${dataId}.json`,
        tempData
      )
      .then((res) => {
        setData(res.data);
        showDialog('success', 'Updated Image successfully', 'success');
        resetField();
      })
      .catch((err) => {
        showDialog('Error', 'Error', 'error');
      });
  };
  const upload = async (file) => {
    if (!file) return;
    const storageRef = ref(storage, file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          setImg(downloadURL);
        });
      }
    );
  };
  return (
    <>
      <main className='col-md-9 ms-sm-auto col-lg-10 px-md-4'>
        <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom'>
          <h1 className='h2 mt-3'>Add New Image </h1>
          <Link to='/dashboard' className='pull-right btn btn-main mb-4 pr-4'>
            back
          </Link>
        </div>

        <div className='row mt-5'>
          <div className='col-md-4'>
            <div className='card task card'>
              <img src={url} className='card-img-top' alt='...' />
            </div>
          </div>
          <form onSubmit={onSubmitHandler}>
            <div className='col-md-12'>
              <div className='form-outline mb-4'>
                <label className='form-label'>Image For Slider</label>
                <input
                  className='form-control'
                  type='file'
                  id='formFile'
                  onChange={(e) => {
                    upload(e.target.files[0]);
                  }}
                  ref={urlRef}
                />
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='pull-right btn btn-main mb-4 pr-4'
                disabled={img ? false : true}>
                {img ? 'Add New Image' : `${progresspercent}%`}
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default NewImage;
