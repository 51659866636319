import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import Swal from 'sweetalert2';

export const HomeURL =
  'https://call4china-default-rtdb.firebaseio.com/home.json';
export const AboutURL =
  'https://call4china-default-rtdb.firebaseio.com/about.json';
export const ServiceURL =
  'https://call4china-default-rtdb.firebaseio.com/service.json';
export const contactURL =
  'https://call4china-default-rtdb.firebaseio.com/contact.json';

export const apiKey = 'AIzaSyA3qkV61b_KaDwZ0Yx4Kpre5OMX7ymvZZg';

export const showDialog = (title, text, icon) => {
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    timer: 1500,
    showCancelButton: false,
    showConfirmButton: false,
  });
};
// const firebaseConfig = {
//   apiKey: 'AIzaSyAqpaAm83shWzXQOqoYYzMHhfGEhNQ5xs0',
//   authDomain: 'layalina-api-b8451.firebaseapp.com',
//   databaseURL: 'https://layalina-api-b8451-default-rtdb.firebaseio.com',
//   projectId: 'layalina-api-b8451',
//   storageBucket: 'layalina-api-b8451.appspot.com',
//   messagingSenderId: '891794995754',
//   appId: '1:891794995754:web:529bbfc9443ad982856153',
//   measurementId: 'G-W037XLCH9H',
// };
const firebaseConfig = {
  apiKey: 'AIzaSyA3qkV61b_KaDwZ0Yx4Kpre5OMX7ymvZZg',
  authDomain: 'call4china.firebaseapp.com',
  projectId: 'call4china',
  storageBucket: 'call4china.appspot.com',
  messagingSenderId: '66258853259',
  appId: '1:66258853259:web:8773a649ee15baaf2b638e',
  measurementId: 'G-MRL8YGMZQM',
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
